export default `
<div class="unauthenticated-container">
  <div>
    <unauthenticated-header ng-show="$ctrl.showHeaderFooter"></unauthenticated-header>

    <div auto-padding="nav-bar-container" auto-padding-event="adjust-nav-padding" id="mainUiView" class='main-ui-view unauthenticated-view' scrolly scroll-target='#navBar' autoscroll="true" ng-style="!$ctrl.showHeaderFooter && {'padding-top':'0px'}">
      <ui-view></ui-view>
    </div>
  </div>
  <unauthenticated-footer ng-show="$ctrl.showHeaderFooter"></unauthenticated-footer>
</div>
`;
