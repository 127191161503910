export default `
<div class="community-feed-item">
  <div class="community-feed-item-main">
    <div class="community-feed-item-header">
      <a class="community-feed-item-header-info" ui-sref="{{$ctrl.authorRoute}}" aria-label="{{$ctrl.type}} Author, {{$ctrl.authorName}}">
        <div ng-if="$ctrl.author && !$ctrl.authorGlyph" ntux-avatar-badges="$ctrl.author" class="community-feed-item-header__avatar"></div>
        <div ng-if="$ctrl.authorGlyph" class="community-feed-item-header__avatar community-feed-item-header__avatar--glyph"><i class="material-icons">{{$ctrl.authorGlyph}}</i></div>
        <div class="community-feed-item-header__author" ui-sref="{{$ctrl.authorRoute}}">
          {{$ctrl.authorName}}
          <small ng-if="$ctrl.authorSubtext">/ {{ $ctrl.authorSubtext }}</small>
        </div>
        <div class="community-feed-item-header__details" ng-if="$ctrl.type !== 'Referral Campaign'">
          <small>{{$ctrl.type}} &#x2022; {{$ctrl.date | relativeDate}}</small>
        </div>
      </a>
      <div class="community-feed-item-header__actions hidden-mobile">
        <community-feed-item-hide ng-if="['status', 'comment', 'article', 'topic'].indexOf($ctrl.item.type) !== -1" item="$ctrl.item" ng-show="$ctrl.item.current_user_permissions.hide"></community-feed-item-hide>
        <community-feed-item-flag item="$ctrl.item" ng-if="$ctrl.item.user.id !== $ctrl.user.id" ng-show="$ctrl.item.current_user_permissions.flag"></community-feed-item-flag>
        <community-feed-item-subscribe item="$ctrl.item" ng-if="['status', 'article', 'topic'].indexOf($ctrl.item.type) !== -1 && $ctrl.item.user.id !== $ctrl.user.id" ng-show="$ctrl.item.current_user_permissions.subscribe"></community-feed-item-subscribe>
        <hidden-menu icon-class="material-icons" icon-name="create" ng-if="$ctrl.item.type === 'status' && ($ctrl.item.current_user_permissions.update || $ctrl.item.current_user_permissions.destroy)">
          <li class="hidden-mobile" ng-if="$ctrl.item.current_user_permissions.update" role="menuitem" ng-mousedown="$ctrl.editStatus()" ng-keydown="$ctrl.menuItemKeydown($event, 'edit')" ng-bind-html="'Edit Status'" tabindex="-1" aria-hidden="{{$ctrl.isHidden}}"</li>
          <li class="hidden-mobile" ng-if="$ctrl.item.current_user_permissions.destroy" role="menuitem" ng-mousedown="$ctrl.deleteStatus()" ng-keydown="$ctrl.menuItemKeydown($event, 'delete')" ng-bind-html="'Delete Status'" tabindex="-1" aria-hidden="{{$ctrl.isHidden}}"></li>
        </hidden-menu>
      </div>
    </div>
    <div ng-class="{ 'community-feed-item-body' : ($ctrl.isLikeable || $ctrl.isCommentable) }">
      <div>
        <div class="community-feed-item-middle-content" ng-if="!$ctrl.editing">
          <a class="community-feed-item-body__title" ng-if="$ctrl.title" ng-bind-html="$ctrl.title | cleanUp | ulinky" ui-sref="{{$ctrl.itemRoute}}"></a>
          <a class="community-feed-item-body__content" ng-class="{ 'community-feed-item-body__content--only' : !$ctrl.title }" ng-bind-html="$ctrl.content | cleanUp | ulinky" ui-sref="{{$ctrl.itemRoute}}"></a>
          <div class="community-feed-item-position" ng-if="$ctrl.isOverflowed() && !$ctrl.hideSeeMore && $ctrl.item.type === 'status'">
            <a ui-sref="{{$ctrl.itemRoute}}" class="hide-text-gradient" aria-hidden="true" tabindex="-1"></a>
            <a ui-sref="{{$ctrl.itemRoute}}" class="community-feed-item-show-more" ng-class="{'community-feed-item-show-more__content--only' : !$ctrl.title}" ng-click="$ctrl.expandStatusCard($event)">&nbsp;&nbsp;... see more</a>
          </div>
          <div class="community-feed-item-position" ui-sref="{{$ctrl.itemRoute}}" ng-if="$ctrl.isOverflowed() && $ctrl.item.type !== 'status'">
            <a ui-sref="{{$ctrl.itemRoute}}" class="hide-text-gradient" aria-hidden="true" tabindex="-1"></a>
            <a ui-sref="{{$ctrl.itemRoute}}" class="community-feed-item-show-more" ng-class="{'community-feed-item-show-more__content--only' : !$ctrl.title}">&nbsp;&nbsp;... see more</a>
          </div>
        </div>
        <status-attachment ng-if="$ctrl.type === 'Status' && $ctrl.item.attached_url" status="$ctrl.item"></status-attachment>
        <community-feed-status-update item="$ctrl.item" ng-if="$ctrl.item.type === 'status'" content="$ctrl.content" editing="$ctrl.editing" ng-show="$ctrl.editing"></community-feed-status-update>
      </div>
      <div class="community-feed-item-body__stats" ng-if="$ctrl.isLikeable">
        <small>
          <ut-community-feed-item-like-count [likeable]="$ctrl.item"></ut-community-feed-item-like-count>
          <button class="func-link community-feed-item-comments" ng-click="$ctrl.toggleComments()">{{$ctrl.numComments | numToK}} Comments</button>
        </small>
      </div>
    </div>
    <div class="community-feed-item-footer" ng-if="$ctrl.isCommentable">
      <ut-community-feed-item-like-btn [likeable]="$ctrl.item" [show-liked]="true"></ut-community-feed-item-like-btn>
      <button class="community-feed-item__button community-feed-item__add-comment-button" ng-click="$ctrl.toggleComments()">
        <i class="material-icons" aria-hidden="true">comment</i> <span class="community-feed-item-footer__text">Add Comment</span>
      </button>
    </div>
  </div>

  <div class="community-feed-item-comment-section" ng-if="$ctrl.user">
    <div class="community-feed-item-comment-form-fullscreen" ng-if="$ctrl.fullScreenOpen">
      <button class="community-feed-item-comment-form-fullscreen__cancel" ng-click="$ctrl.closeFullScreen()">Cancel</button>
      <button class="community-feed-item-comment-form-fullscreen__submit" ng-click="$ctrl.submitComment()" ng-disabled="$ctrl.disablePostComment">Post</button>
      <ut-editor [(value)]="$ctrl.newComment.content" text-area-label="Write your comment here"></ut-editor>
    </div>
    <form class="community-feed-item-comment-form" ng-show="$ctrl.showComments" ng-submit="$ctrl.submitComment()">
      <div ng-if="$ctrl.author && !$ctrl.authorGlyph" tabindex="0" ntux-avatar-badges="$ctrl.user" class="community-feed-item-comment-form__avatar"></div>
      <div ng-if="$ctrl.authorGlyph" class="community-feed-item-comment-form__avatar community-feed-item-comment-form__avatar--glyph"><i class="material-icons">{{$ctrl.authorGlyph}}</i></div>
      <button type="button" class="community-feed-item-comment-form__fullscreen-button hidden-lg hidden-md hidden-sm" ng-click="$ctrl.openFullScreen()">{{$ctrl.newComment.content || 'Add your comment...'}}</button>
      <div class="community-feed-item-comment-form__input hidden-xs">
        <ut-editor [(value)]="$ctrl.newComment.content" text-area-label="Write your comment here"></ut-editor>
      </div>
      <button class="community-feed-item-comment-form__button hidden-xs" ng-disabled="$ctrl.disablePostComment">Post</button>
    </form>
    <community-feed-item-comment comment="comment" ng-show="$ctrl.showComments" ng-repeat="comment in $ctrl.item.recent_comments"></community-feed-item-comment>
    <community-feed-item-comment comment="comment" ng-show="$ctrl.showComments" ng-repeat="comment in $ctrl.item.comment_feed.feedItems"></community-feed-item-comment>
    <ntux-load-more ng-show="$ctrl.showComments && $ctrl.numComments > 2" load-callback="$ctrl.loadMoreComments()" is-loading="$ctrl.item.comments.isLoading" is-last-page="$ctrl.item.comment_feed.isLastPage && $ctrl.item.comment_feed.feedItems.length > 0">
      <button class="community-feed-item__load-more">Show more comments</button>
    </ntux-load-more>
  </div>
</div>
`;
