import appTemplate from '../layouts/app.html';
import HomeFeedService from '../components/home/home-feed.service';
import UnauthenticatedAssetsService from '../components/services/unauthenticated-assets.service';
import CommunityManagersService from '../components/services/community-managers.service';
import community from '../components/community/community.component';
import home from '../components/home/home.component';
import { WhyUtestComponent } from '../ng-app/home/why-utest/why-utest.component';
import UserResolver from '../authentication/user-resolver';
import UserAuthenticator from '../authentication/user-authenticator';
import FeatureToggleResolver from '../feature-toggles/feature-toggle-resolver';
import { AboutUsComponent } from '../ng-app/home/about-us/about-us.component';

const HomeRouter = {
  route: (module) => {
    module
      .service('HomeFeedService', HomeFeedService)
      .service('UnauthenticatedAssetsService', UnauthenticatedAssetsService)
      .service('CommunityManagersService', CommunityManagersService)
      .component('home', home)
      .component('community', community)
      .config(['$stateProvider', ($stateProvider) => {
        $stateProvider
          .state('root', {
            controller: ['$state', '$scope', '$rootScope', 'featureFlags', 'user', ($state, $scope, $rootScope, featureFlags, user) => {
              $scope.$state = $state;
              $scope.featureFlags = featureFlags;
              if (user) {
                $scope.currentUser = user;
                $rootScope.showNtuxNavigation = true;
                $rootScope.scrollBehaviorClass = 'overflow-hidden';
              } else {
                $rootScope.showNtuxNavigation = false;
                $rootScope.scrollBehaviorClass = 'overflow-scroll';
              }
            }],
            url: '',
            abstract: true,
            template: appTemplate,
            resolve: {
              features: FeatureToggleResolver,
              user: UserResolver,
            },
          })
          .state('home', {
            url: '/',
            parent: 'root',
            component: 'home',
            resolve: {
              user: UserResolver,
              title: [() => 'uTest - The Professional Network for Testers'],
              staticPage: ['StaticPageService', '$rootScope', function (StaticPageService, $rootScope) {
                return StaticPageService.get('home-5tat1c').then((response) => {
                  if (response.data) {
                    $rootScope.showUnauthenticatedHeaderFooter = false;
                    return response.data;
                  }
                }).catch(() => {
                  $rootScope.showUnauthenticatedHeaderFooter = true;
                  return false;
                });
              }],
            },
          })
          .state('about-us', {
            url: '/about-us?section',
            component: AboutUsComponent,
            parent: 'root',
            resolve: {
              title: [() => 'uTest - About Us'],
              sectionToScroll: ['$transition$', function ($transition$) {
                return $transition$.params().section;
              }],
              staticPage: ['StaticPageService', '$rootScope', function (StaticPageService, $rootScope) {
                return StaticPageService.get('about-us').then((response) => {
                  if (response.data) {
                    $rootScope.showUnauthenticatedHeaderFooter = false;
                    return response.data;
                  }
                }).catch(() => {
                  $rootScope.showUnauthenticatedHeaderFooter = true;
                  return false;
                });
              }],
              meta: ['title', 'Meta', function (title, Meta) {
                Meta.updateTag({ name: 'og:title', property: 'og:title', content: title });
              }],
            },
          })
          .state('why-utest', {
            url: '/why-utest',
            component: WhyUtestComponent,
            parent: 'root',
            resolve: {
              title: [() => 'uTest - Why uTest?'],
              staticPage: ['StaticPageService', '$rootScope', function (StaticPageService, $rootScope) {
                return StaticPageService.get('why-utest').then((response) => {
                  console.log(response);
                  if (response.data) {
                    $rootScope.showUnauthenticatedHeaderFooter = false;
                    return response.data;
                  }
                }).catch(() => {
                  $rootScope.showUnauthenticatedHeaderFooter = true;
                  return false;
                });
              }],
              meta: ['title', 'Meta', function (title, Meta) {
                Meta.updateTag({ name: 'og:title', property: 'og:title', content: title });
              }],
            },
          })
          .state('community', {
            url: '/community',
            component: 'community',
            parent: 'root',
            resolve: {
              user: UserAuthenticator,
              title: [() => 'uTest Community Feed'],
            },
          });
      }]);
  },
};

export default HomeRouter;
