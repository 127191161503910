import template from './home.component.html';
import controller from './home.controller';

const home = {
  bindings: {
    user: '<',
    staticPage: '<',
  },
  template,
  controller,
};

export default home;
