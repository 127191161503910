/*
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';
import * as Ladda from 'ladda';
import {
  EMAIL_REGEX, NAME_REGEX,
} from '../constants';

class SimpleRegistrationFormCtrl {
  constructor($scope, UserService, ConfigService, vcRecaptchaService, $analytics, $location, AccessibleFormService, StaticValuesService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = function () {
      ctrl.spinnerBtn = Ladda.create(document.querySelector('#laddaBtn'));
      ctrl.name_pattern = new RegExp(NAME_REGEX);
      ctrl.email_pattern = new RegExp(EMAIL_REGEX);

      ctrl.registrationData = {
        firstName: '',
        lastName: '',
        email: '',
        paypalEmail: '',
        recaptcha_response: '',
        countryId: '',
        birthDate: {},
      };
      if (ctrl.preparedRegistrationData?.campaigns) {
        ctrl.registrationData.campaigns = ctrl.preparedRegistrationData.campaigns;
      }
      ctrl.paypalEmailSame = false;
      ctrl.completedRegistration = false;
      ctrl.failedRegistration = false;
      ctrl.countries = [];

      const registrationQueryParameters = $location.search();
      if (!!registrationQueryParameters && Object.keys(registrationQueryParameters).length > 0) {
        ctrl.registrationData.registrationLocation = JSON.stringify(registrationQueryParameters);
      }

      ctrl.submissionInProgress = false;

      ConfigService.config().then((res) => {
        ctrl.globalConfig = res.data;

        ctrl.recaptchaKey = ctrl.globalConfig.recaptcha_client_key;
        ctrl.showExpiredRecaptchaError = false;
      });

      StaticValuesService.countries().then((countries) => {
        ctrl.countries = countries.data;
      });

      geolocate();
    };

    ctrl.togglePaypalEmailSame = function () {
      ctrl.paypalEmailSame = !ctrl.paypalEmailSame;
      if (ctrl.paypalEmailSame) {
        ctrl.registrationData.paypalEmail = ctrl.registrationData.email;
      }
    };

    ctrl.updatePayPalEmail = function () {
      if (ctrl.paypalEmailSame) {
        ctrl.registrationData.paypalEmail = ctrl.registrationData.email;
      }
    };

    ctrl.updatePaymentType = function () {
      if (ctrl.registrationData.paymentType === 'later') {
        ctrl.registrationData.paypalEmail = '';
        ctrl.paypalEmailSame = false;
      }
    };

    // FORM SUBMISSION
    ctrl.submitForm = function (form) {
      if (ctrl.isValidForm(form) && !ctrl.submissionInProgress) {
        ctrl.startSpinner();
        ctrl.createUser();
      } else {
        AccessibleFormService.focusFirstError(form, $scope);
      }
    };

    ctrl.isValidForm = function (form) {
      form.$$submitted = true;
      return !Object.keys(form.$error).length;
    };

    ctrl.createUser = function () {
      return UserService.createLightTester(ctrl.registrationData).then((response) => {
        ctrl.stopSpinner();
        ctrl.user = response.data;
        if (ctrl.customRegistration) {
          ctrl.customRegistration.embedded_form = ctrl.customRegistration.embedded_form.replace('$id$', encodeURIComponent(ctrl.user.testerId));
          ctrl.customRegistration.embedded_form = ctrl.customRegistration.embedded_form.replace('$name$', encodeURIComponent(ctrl.user.fullName));
          ctrl.customRegistration.embedded_form = ctrl.customRegistration.embedded_form.replace('$email$', encodeURIComponent(ctrl.user.email));
        }
        ctrl.trackRegistration();
        ctrl.completedRegistration = true;
      }, () => {
        ctrl.stopSpinner();
        ctrl.failedRegistration = true;
      });
    };

    ctrl.isEmptyKeyInObject = function (obj) {
      if (Object.keys(obj).length > 0) {
        return !Object.keys(obj).every(key => obj[key] !== '' && obj[key] !== null && obj[key] !== undefined);
      }
      return true;
    };

    ctrl.startSpinner = function () {
      ctrl.submissionInProgress = true;
      ctrl.spinnerBtn.start();
    };

    ctrl.stopSpinner = function () {
      ctrl.spinnerBtn.stop();
      ctrl.spinnerBtn.remove();
      ctrl.submissionInProgress = false;
    };

    ctrl.trackRegistration = () => {
      const hostsToExclude = '(integration|stage|localhost)';
      if (!$location.$$host.match(hostsToExclude)) {
        $analytics.eventTrack('click', { category: 'Light User Registration', label: 'completed registration' });
      }
    };

    ctrl.setWidgetId = function (widgetId) {
      ctrl.captchaWidgetID = widgetId;
    };

    ctrl.onCaptchaResponse = function (response) {
      ctrl.showExpiredRecaptchaError = false;
      ctrl.registrationData.recaptcha_response = response;
    };

    ctrl.cbExpiration = function () {
      ctrl.showExpiredRecaptchaError = true;
      vcRecaptchaService.reload(ctrl.captchaWidgetID);
      ctrl.registrationData.recaptcha_response = null;
    };

    ctrl.years = StaticValuesService.years();
    ctrl.months = StaticValuesService.months();
    ctrl.days = StaticValuesService.days();

    ctrl.toNumber = function (value: string) {
      return parseInt(value);
    };

    ctrl.selectDay = function () {
      const { day, month, year } = ctrl.registrationData.birthDate;
      const dayAsNumber = ctrl.toNumber(day);
      ctrl.days = StaticValuesService.days(month, year);
      // if selected date does not exist in selected year or month then reset the date
      if (ctrl.days.indexOf(dayAsNumber) === -1) {
        ctrl.registrationData.birthDate.day = undefined;
      }
    };

    ctrl.selectMonth = function () {
      ctrl.selectDay();

      const { month, year } = ctrl.registrationData.birthDate;
      ctrl.months = StaticValuesService.months(year);

      // if selected month is not valid in selected year then reset the month
      if (month && Object.keys(ctrl.months).indexOf((month - 1).toString()) === -1) {
        ctrl.registrationData.birthDate.month = '';
      }
    };

    ctrl.selectYear = function () {
      ctrl.selectDay();
      ctrl.selectMonth();
    };

    ctrl.selectCountry = function () {
      ctrl.autoDetectAddress.country = false;
    };

    ctrl.isGoogleMapsAvailable = async function () {
      let retries = 0;
      const maxRetries = 5;

      while (retries < maxRetries) {
        if (_.isObject(window.google)) {
          return true;
        }
        retries++;
        await sleep(500); // eslint-disable-line
      }

      return false;
    };

    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms)); // eslint-disable-line
    }

    async function geolocate() {
      if (await ctrl.isGoogleMapsAvailable()) {
        StaticValuesService.geolocate().then((geolocate) => {
          const latLng = geolocate.data.location;

          ctrl.geocoder = new google.maps.Geocoder();

          ctrl.geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK') {
              const address: any = {};
              results[0].address_components.forEach((el) => {
                if (el.types[0] === 'administrative_area_level_1') {
                  address[el.types[0]] = el.long_name;
                } else {
                  address[el.types[0]] = el.short_name;
                }
              });

              const selectedCountry = _.find($scope.countries, country => country.code === address.country); // eslint-disable-line
              if (selectedCountry) {
                $scope.registrationData.countryId = selectedCountry.id;
                ctrl.autoDetectAddress.country = true;
              }

              $scope.$apply();
            }
          });
        });
      }
    }
  }
}

SimpleRegistrationFormCtrl.$inject = ['$scope', 'UserService', 'ConfigService', 'vcRecaptchaService', '$analytics', '$location', 'AccessibleFormService', 'StaticValuesService', 'uiGmapGoogleMapApi'];

export default SimpleRegistrationFormCtrl;
