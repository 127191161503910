import angular from 'angular';

class NtuxSelectCtrl {
  constructor($window) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      ctrl.options = ctrl.selectOptions;
      ctrl.value = ctrl.options[0].value;
      ctrl.selectedDisplay = ctrl.options[0].selectedDisplay || ctrl.options[0].display;
      ctrl.isOpen = false;
      ctrl.selectedValueIndex = 0;
      ctrl.totalOptions = ctrl.options.length;
      ctrl.optionsArray = [];

      angular.element($window).on('click resize', (e) => {
        // @ts-ignore
        if (e.type === 'resize' || !e.target.closest('.ntux-select')) {
          ctrl.hideOptions('resize');
        }
      });
      // adds options to optionsArray in order to track index of selected value
      for (let i = 0; i < ctrl.totalOptions; i++) {
        ctrl.optionsArray[i] = ctrl.options[i].selectedDisplay || ctrl.options[i].display;
      }
    };

    ctrl.setValue = function (option) {
      ctrl.value = option.value;
      ctrl.selectedDisplay = option.selectedDisplay || option.display;
      ctrl.selectCallback({ value: ctrl.value });
      ctrl.selectedValue = true;
    };

    ctrl.toggleOptions = function () {
      const element = $('.ntux-select-options');
      const ntuxSelectOption = $('.ntux-select-option');
      const button = $('.ntux-select-value');
      const viewportWidth = window.innerWidth;
      const elementWidth = element.width();
      const parentX = element.parent()[0].getBoundingClientRect().left;
      // 30 for double sidebar, 24 for padding that isn't included in width() and 4 so we can also see the border and shadow
      const xOverflow = (parentX + elementWidth + 30 + 24 + 4) - viewportWidth;

      if (elementWidth > 0) {
        element.css('left', element.parent().prop('offsetLeft'));
      }

      if (xOverflow > 0) {
        element.css('left', element.parent().prop('offsetLeft') - xOverflow);
      }
      if (element.css('display') === 'block') {
        element.css('display', 'none');
        button[0].focus();
      } else {
        element.css('display', 'block');
        const i = ctrl.optionsArray.indexOf(ctrl.selectedDisplay);
        ntuxSelectOption[i].focus();
      }
      ctrl.isOpen = !ctrl.isOpen;
    };

    ctrl.handleKeydown = function (e, o) {
      if (['Enter', 'Space'].includes(e.code) && ctrl.isOpen) {
        ctrl.setValue(o);
        ctrl.hideOptions('setFocus');
      }
      if (['Escape'].includes(e.code) && ctrl.isOpen) {
        ctrl.hideOptions('setFocus');
      }
      if (['ArrowDown', 'ArrowUp'].includes(e.code)) {
        e.preventDefault();
        ctrl.handleUpDownArrows(e.code);
      }
      if (['Tab'].includes(e.code) && e.shiftKey) {
        ctrl.hideOptions('setFocus');
      } else if (['Tab'].includes(e.code)) {
        ctrl.hideOptions('resize');
      }
    };

    ctrl.hideOptions = function (reason) {
      $('.ntux-select-options').css('display', 'none');
      ctrl.isOpen = false;
      if (reason === 'setFocus') {
        setTimeout(() => {
          $('.ntux-select-value')[0].focus();
        }, 100);
      }
    };

    ctrl.handleUpDownArrows = (direction) => {
      const ntuxSelectOption = $('.ntux-select-option');
      switch (direction) {
        case 'ArrowDown':
          if (ctrl.selectedValueIndex < (ctrl.totalOptions - 1)) {
            ntuxSelectOption[ctrl.selectedValueIndex + 1].focus();
            ctrl.selectedValueIndex++;
          }
          break;
        case 'ArrowUp':
          if (ctrl.selectedValueIndex > 0) {
            ntuxSelectOption[ctrl.selectedValueIndex - 1].focus();
            ctrl.selectedValueIndex--;
          }
          break;
        default:
      }
    };
  }
}

NtuxSelectCtrl.$inject = ['$window'];

export default NtuxSelectCtrl;
